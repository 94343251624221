@import "~semantic-ui-css/semantic.min.css";

body {
  margin: 0;
  font-family: "Sue Ellen Francisco", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui.selection.dropdown > .icon {
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0.78571429em;
  right: 1em;
  z-index: 3;
  margin: -0.78571429em;
  padding: 0.91666667em;
  opacity: 0.8;
  transition: opacity 0.1s ease;
}
.card-reveal {
  display: block;
  border-radius: 0.28571429rem;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
  .card-reveal-content {
    background: rgba($color: #575757, $alpha: 0.93);
    font-family: "Sue Ellen Francisco", cursive;
    position: absolute;
    overflow: hidden;
    color: #fff;
    width: 100%;
    height: 0;
    bottom: 0;
  }
  &:hover .card-reveal-content {
    transition: height 0.3s;
    display: flex;
    flex-direction: column;
    height: 100%;
    .ui.segment {
      overflow: auto;
      flex: 1;

      .header {
        margin-bottom: 2rem;
        font-size: 3rem;
        &.right {
          text-align: right;
          font-size: 2rem;
          color: orange;
        }
      }
      .meta {
        margin-bottom: 2rem;
        font-size: 1.5rem;
        color: #fff;
        line-height: 1.3rem;
      }
      .description {
        font-size: 1.8rem;
        font-weight: normal;
        line-height: 1.37;
      }
    }
    .ui[class*="bottom attached"].menu {
      border-bottom: 4px solid orange;
      border-radius: none !important;
    }
  }
}
.ui.modal {
  .content {
    .header {
      font-size: 3rem;
    }
    .description {
      font-size: 1.8rem;
      font-weight: normal;
      line-height: 1.37;
    }
  }
}

.grid {
  position: relative;
  .item {
    width: calc(32% - 20px);
    position: absolute;
    .item-content {
      position: relative;
      img{
        display: block;
        border-radius: 6px;
      }
    }
  }
}
